import React from "react";
import "../estilos/Loader.css";

function Loader() {
  return (
    <div className="spinner-box position-absolute top-50 start-50 translate-middle">
      <svg height="100" width="100" viewBox="0 0 24 24">
        <path
          class="circle"
          d="m11.262 2.306c.196-.196.461-.306.738-.306s.542.11.738.306c1.917 1.917 7.039 7.039 8.956 8.956.196.196.306.461.306.738s-.11.542-.306.738c-1.917 1.917-7.039 7.039-8.956 8.956-.196.196-.461.306-.738.306s-.542-.11-.738-.306c-1.917-1.917-7.039-7.039-8.956-8.956-.196-.196-.306-.461-.306-.738s.11-.542.306-.738c1.917-1.917 7.039-7.039 8.956-8.956z"
          stroke="#64ffda"
          stroke-width="1"
          fill-opacity="0"
        />

        {/*
                <circle
          class="circle"
          cx="50"
          cy="50"
          r="35"
          stroke="#64ffda"
          stroke-width="3"
          fill-opacity="0"
          transform="rotate(-90 50 50)"
        />
        */}

      </svg>
    </div>
  );
}

export default Loader;
