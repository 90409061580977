import React from 'react'
import { FaGithub } from 'react-icons/fa'
import { FiLinkedin } from "react-icons/fi";
import { RiFileUserLine } from "react-icons/ri";



function DivFlotanteLaterales(props) {

  return (
    <div className="divFlotante row">
      <div className="col-md-6 m-0">
        <div
          className="row position-absolute bottom-0 mx-4 px-4"
          data-aos="fade"
          data-aos-duration="3000"
          data-aos-delay="3000"
        >
          <div className="mx-3 mb-3">
            <a href={ props.gitHubLink } target="_blank" rel="noreferrer">
              <FaGithub className='fontColor1 navElement pe-auto' size={24} />
            </a>
          </div>
          <div className="mx-3 mb-3">
            <a href={ props.linkedinLink } target="_blank" rel="noreferrer">
              <FiLinkedin className='fontColor1 navElement pe-auto' size={24}/>
            </a>
          </div>
          <div className="mx-3 mb-3">
            <a className='cursor-pointer' href={ props.curriculum } target="_blank" rel="noreferrer">
              <RiFileUserLine className='fontColor1 navElement pe-auto' size={25}/>
            </a>
          </div>
          <div className="verticalLine"></div>
        </div>
      </div>
      <div className="col-md-6 m-0">
        <div
          className="float-end row"
          data-aos="fade"
          data-aos-duration="3000"
          data-aos-delay="3000"
        >
          <a href={ 'mailto:' + props.emailDeContacto } target="_blank" rel="noreferrer" className="colorTexto float-end verticalText fontColor1 fontFamily1 navElement pe-auto">
            <p className="colorTexto float-end verticalText fontColor1 fontFamily1 navElement">{ props.emailDeContacto }</p>
          </a>
          <div className="verticalLine"></div>
        </div>
      </div>
    </div>
  )
}

export default DivFlotanteLaterales