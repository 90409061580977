import React from 'react'

function AboutMe() {
  return (
    <div className="row newContainer aboutMe my-0 aos-init aos-animate" id="aboutMe" data-aos="fade">

      <div className="mb-4">
        <h4 className="bg-green fontFamily1 d-inline-block">01.</h4>
        <h2 className="fontColor1 fontFamily2 d-inline-block">Acerca de mí</h2>
        <hr className="hr1 d-inline-block m-0 mb-2 mx-3" />
      </div>

      <div className="col-md-6">
        <p className="colorTexto">
          Cuento con un fuerte interés por la programación y la creación de sitios web. Durante mis estudios, 
          he adquirido habilidades en el uso de tecnologías como <span className="bg-green">HTML, CSS, JavaScript </span> 
          y tengo experiencia en proyectos personales usando frameworks como 
          <span className="bg-green"> ReactJS, </span>
          <span className="bg-green">NodeJS y </span>
          <span className="bg-green">Bootstrap.</span> 
        </p>  
        <p className="colorTexto">
          Estoy dispuesto a seguir aprendiendo y trabajar duro para convertirme en un desarrollador valioso para mi equipo. 
        </p>
        <p className="colorTexto">
          Me encuentro constantemente mejorando mis habilidades para seguir ampliando mi conocimiento en el desarrollo de sitios web. 
        </p>
      </div>

      <div className="col-md-6">
        <div className="imgPhotoContainer rounded d-inline-block">
          
          <img src={require('../img/1.jpg')} alt="" className="img-fluid rounded imgPhoto" />
        </div>
      </div>

    </div>
  )
}

export default AboutMe