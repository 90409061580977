import React from "react";

function ContactoDiv(props) {
  return (
    <div
      className="newContainer2 text-center contactContainer"
      id="contact"
      data-aos="fade-up"
    >
      <a href={ 'mailto:' + props.emailDeContacto } target="_blank" rel='noreferrer'>
        <span className="material-symbols-outlined bg-green">email</span>
      </a>
      <h2 className="fontColor1 fontFamily2">Ponerse en contacto</h2>
      <p className="colorTexto">
        Actualmente me encuentro en busca de nuevas oportunidades, mi bandeja de
        entrada siempre está abierta a cualquier propuesta o proyecto. ¡No dude
        en enviarme un correo!
      </p>
      <a href={ 'mailto:' + props.emailDeContacto } target="_blank" rel="noreferrer" className="colorTexto fontColor1 fontFamily1 navElement pe-auto" style={{textDecoration: 'none'}}>
        <p className="fontFamily1 mt-4">
          { props.emailDeContacto }
        </p>
      </a>
      <br />
      <a className="buttonGreen p-3" href={ 'mailto:' + props.emailDeContacto } >
        Enviar Mensaje
      </a>
    </div>
  );
}

export default ContactoDiv;
