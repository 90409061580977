import React from "react";

function Separador( props ) {
    const returnBr = () => {
        const brArray = [];
        for (let index = 0; index < props.espacios; index++) {
          brArray.push(<br key={index} />);
        }
        return brArray;
      };
    
      return <>{returnBr()}</>;
    }

export default Separador;
