import React, { useLayoutEffect } from 'react'
import Loader from './Loader';

function LoadScreen() {

useLayoutEffect(() => {
  var loader = document.getElementById("preloader");
  var opacity = 0;
  var intervalID = 0;

/* Funcion auxiliar de la funcion fadeOut() */ 
function hide(){
  opacity = Number(window.getComputedStyle(loader).getPropertyValue("opacity"));

  if(opacity > 0){
      opacity = opacity - 0.025;
      loader.style.opacity = opacity;
  }else{

      clearInterval(intervalID);
      loader.style.display = "none";
      document.body.style.overflow = 'visible'
  }

}

  /* Funcion para desvanecer el load screen */
  function fadeOut(){
    intervalID = setInterval(hide, 20);
  }

  /* Se encarga de llamar a la funcion fadeOut() luego de 2 segundos de haber cargado la pagina */
  setTimeout( function(){
    fadeOut();
  }, 2800 )
}, [])


  return (
    <div id="preloader">

      {/*
      <div className="spinner-box position-absolute top-50 start-50 translate-middle">
        <div className="three-quarter-spinner"></div>
      </div>
       */}
      
      <Loader />

      <div className="spinner-box position-absolute top-50 start-50 translate-middle">
          <h3 className='fontFamily2 bg-green m-0 p-0 logoSvg'>M</h3>
      </div>

    </div>
  )
}

export default LoadScreen