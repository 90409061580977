import React from "react";
import BotonTecnologia from "./BotonTecnologia";

function Proyectos() {
  return (
    <div
      className="row projectsContainer newContainer"
      id="projects"
      data-aos="fade"
    >
      <div className="mb-4">
        <h4 className="bg-green fontFamily1 d-inline-block">04.</h4>
        <h2 className="fontColor1 fontFamily2 d-inline-block">Proyectos</h2>
        <hr className="hr1 d-inline-block m-0 mb-2 mx-3" />
      </div>

      <div  
          className="pb-4"
          data-aos="fade-down"
          data-aos-delay="0"
      >
        <a
          className="projectsLink p-0"
          href="https://dripdrop.martinn.uy/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="row col-md-12 m-0 pt-5 pb-2 rounded projectsDiv">
            <div className="col-md-5">
              <img
                className="img-fluid imgProjects mb-3"
                src={require("../img/projects/4.png")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <h4 className="fontColor1 fontFamily1 projectTitle">
                DripDrop Tienda Online
                <span className="material-symbols-outlined fontColor1 projectTitle pe-auto">
                  arrow_outward
                </span>
              </h4>
              <p className="fontColor1">
                Una plataforma que ofrece una experiencia de compra online, 
                con atención a la usabilidad y al detalle.
                Creada con la pila MERN y utilizando su propia API.
              </p>
              
              <BotonTecnologia nombre="MongoDB" />
              <BotonTecnologia nombre="Express" />
              <BotonTecnologia nombre="ReactJS" />
              <BotonTecnologia nombre="NodeJS" />
              <BotonTecnologia nombre="Bootstrap" />
              <BotonTecnologia nombre="JWT" />
              <BotonTecnologia nombre="MercadoPago" />

            </div>
          </div>
        </a>

        <div className="accordion newAcordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <div className="row">
                <button
                  className="accordion-button collapsed bg-green d-block text-center mx-auto col-md-4 p-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{width: "250px"}}
                >
                  <div>
                    <span className="material-symbols-outlined d-inline-block" style={{fontSize: "15px"}}>
                      arrow_back_ios
                    </span>
                    <h5 className="fontColor1 fontFamily1 navElement d-inline-block">Más Información</h5>
                    <span className="material-symbols-outlined d-inline-block" style={{fontSize: "15px"}}>
                    arrow_forward_ios
                    </span>
                  </div>
                </button>
              </div>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body row border-bottom border-secondary">
                
                <h4 className="fontColor1 mb-4">Características implementadas:</h4>
                
                <div className="col-md-4">
                  <p className="fontColor1"><span className="bg-green">• </span>Registrarse / Iniciar sesión.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Actualizar perfil y contraseña.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Restablecer contraseña.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Agregar o quitar artículos del carrito.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Agregar o quitar artículos de favoritos.</p>
                </div>
                
                <div className="col-md-4">
                  <p className="fontColor1"><span className="bg-green">• </span>Búsqueda de Producto.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Filtros de productos según categoría.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Información de envío.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Mis pedidos realizados.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Detalles de todos los artículos.</p>
                </div>
                
                <div className="col-md-4">
                  <p className="fontColor1"><span className="bg-green">• </span>Revisar mis compras.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Administrador: acceso al panel.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Administrador: Agregar / Actualizar Productos.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Administrador: Actualizar datos de usuario.</p>
                  <p className="fontColor1"><span className="bg-green">• </span>Gestión de stock: Disminuir stock de productos.</p>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div
      data-aos="fade-down"
      data-aos-delay="0"
      >
        <a
          className="projectsLink p-0"
          href="https://brandead.net/"
          target="_blank"
          rel="noreferrer"
          
        >
          <div className="row col-md-12 m-0 py-5 rounded projectsDiv">
            <div className="col-md-5">
              <img
                className="img-fluid imgProjects mb-3"
                src={require("../img/projects/5.png")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <h4 className="fontColor1 fontFamily1 projectTitle">
                Brandead Marketing Digital
                <span className="material-symbols-outlined fontColor1 projectTitle pe-auto">
                  arrow_outward
                </span>
              </h4>
              <p className="fontColor1">
              Un sitio web para una agencia de marketing digital, enfocado en presentar servicios de manera clara y atractiva, 
              optimizando la navegación y mejorando la interacción con los usuarios.
              </p>
              
              {/*
              <span className="material-symbols-outlined colorTexto d-block mb-3">
                data_object
              </span> */
              }

              <BotonTecnologia nombre="MongoDB" />
              <BotonTecnologia nombre="Express" />
              <BotonTecnologia nombre="ReactJS" />
              <BotonTecnologia nombre="NodeJS" />
              <BotonTecnologia nombre="Bootstrap" />

            </div>
          </div>
        </a>
      </div>

      <div
        data-aos="fade-down"
        data-aos-delay="0"
      >
        <a
          className="projectsLink p-0"
          href="https://martinn.uy/"
          target="_blank"
          rel="noreferrer"

        >
          <div className="row col-md-12 m-0 py-5 rounded projectsDiv">
            <div className="col-md-5">
              <img
                className="img-fluid imgProjects mb-3"
                src={require("../img/projects/1.png")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <h4 className="fontColor1 fontFamily1 projectTitle">
                Portfolio Web
                <span className="material-symbols-outlined fontColor1 projectTitle pe-auto">
                  arrow_outward
                </span>
              </h4>
              <p className="fontColor1">
                Un sitio web que muestra de manera organizada una variedad de
                proyectos y habilidades. Diseño y contenido pensados para
                destacar de manera profesional.
              </p>
              
              {/*
              <span className="material-symbols-outlined colorTexto d-block mb-3">
                data_object
              </span> */
              }

              <BotonTecnologia nombre="ReactJS" />
              <BotonTecnologia nombre="ThreeJS" />
              <BotonTecnologia nombre="Bootstrap" />

            </div>
          </div>
        </a>
      </div>

      <a
        className="projectsLink p-0"
        href="https://ph.martinn.uy/"
        target="_blank"
        rel="noreferrer"
        data-aos="fade-down"
        data-aos-delay="1000"
      >
        <div className="row col-md-12 m-0 py-5 rounded projectsDiv">
          <div className="col-md-5">
            <img
              className="img-fluid imgProjects mb-3"
              src={require("../img/projects/3.png")}
              alt=""
            />
          </div>
          <div className="col-md-7">
            <h4 className="fontColor1 fontFamily1 projectTitle">
              Exploración Fotográfica
              <span className="material-symbols-outlined fontColor1 projectTitle pe-auto">
                arrow_outward
              </span>
            </h4>
            <p className="fontColor1">
              Un elegante portafolio web de fotografía que presenta capturas en
              una galería interactiva. Muestra un trabajo visual en un entorno
              digital cuidadosamente diseñado.
            </p>

            <BotonTecnologia nombre="HTML" />
            <BotonTecnologia nombre="CSS" />
            <BotonTecnologia nombre="JavaScript" />
            <BotonTecnologia nombre="Bootstrap" />

          </div>
        </div>
      </a>

      <a
        className="projectsLink p-0"
        href="https://lol.martinn.uy/"
        target="_blank"
        rel="noreferrer"
        data-aos="fade-down"
        data-aos-delay="500"
      >
        <div className="row col-md-12 m-0 py-5 rounded projectsDiv">
          <div className="col-md-5">
            <img
              className="img-fluid imgProjects mb-3"
              src={require("../img/projects/2.png")}
              alt=""
            />
          </div>
          <div className="col-md-7">
            <h4 className="fontColor1 fontFamily1 projectTitle">
              CoolDown Tracker
              <span className="material-symbols-outlined fontColor1 projectTitle pe-auto">
                arrow_outward
              </span>
            </h4>
            <p className="fontColor1">
              Una herramienta externa para League of Legends que te permite
              mantener un seguimiento preciso de los tiempos de reutilización de
              habilidades de tus enemigos, mejorando así tu toma de decisiones
              en el juego.
            </p>
            
            <BotonTecnologia nombre="HTML" />
            <BotonTecnologia nombre="CSS" />
            <BotonTecnologia nombre="JavaScript" />
            <BotonTecnologia nombre="Bootstrap" />

          </div>
        </div>
      </a>
    </div>
  );
}

export default Proyectos;
