import React from 'react'
import Separador from './Separador'


function Formacion() {
  return (
    <div className="newContainer2 overflow-hidden" id="training" data-aos="fade" style={{ paddingTop: '16vh' }}>

    <div className="mb-4">
      <h4 className="bg-green fontFamily1 d-inline-block">03.</h4>
      <h2 className="fontColor1 fontFamily2 d-inline-block">Formación</h2>
      <hr className="hr1 d-inline-block m-0 mb-2 mx-3" />
    </div>

    {/* Certificado 0 Udelar */}
    <div className="container d-inline-block my-4 py-4 position-relative" data-aos="fade-right">
      <div className="formacionContainer position-absolute d-inline-block">
        <a className='cursor-pointer' href='/documents/Escolaridad.pdf' target="_blank" rel='noreferrer' >
          <img src={require("../img/udelar.jpg")} alt="" className="img-fluid col-md-7 imgPhoto rounded position-relative float-end" />
        </a>
      </div>

      <div className="formacionContainer position-relative w-50 mt-5">
        <p className="fontFamily1 bg-green">Estudiante de Ingeniería en Computación</p>
        <h5 className="fontColor1 fontFamily2">Udelar</h5>
        <div className="bg-blue shadow p-3 rounded">
          <p className="fontColor1">
            La carrera de ingeniería en computación apunta a una cobertura amplia y coherente en la planificación, desarrollo, mantenimiento y aplicación de sistemas computarizados. Se destacan la aprobación de los cursos <span className="bg-green">Programación 1 y Programación 2.</span>
          </p>
        </div>

        <a className="navElement fontColor1 px-1 d-block mb-2 cursor-pointer" style={{ textDecoration: 'none' }} href='/documents/Escolaridad.pdf' target="_blank" rel='noreferrer' >Ver escolaridad</a>
        <a className='cursor-pointer' href='/documents/Escolaridad.pdf' target="_blank" rel='noreferrer' ><span className="material-symbols-outlined fontColor1 navElement">link</span></a>
      </div>
    </div>
    
    <Separador espacios="7" />

    {/* Certificado 1 Jap */}
    <div className="container d-inline-block mb-4 pb-4 position-relative" data-aos="fade-left">
      <div className="formacionContainer position-absolute d-inline-block">
        <a className='cursor-pointer' href='/documents/Jap.pdf' target="_blank" rel='noreferrer' >
          <img src={require("../img/jap.jpg")} alt="" className="img-fluid col-md-7 imgPhoto rounded position-relative" />
        </a>
      </div>

      <div className="formacionContainer position-relative float-end floatDiv w-50 mt-5">
        <p className="fontFamily1 bg-green">Desarrollo Web</p>
        <h5 className="fontColor1 fontFamily2">Jóvenes a Programar</h5>
        <div className="bg-blue shadow p-3 rounded">
          <p className="fontColor1">Introducción <span className="bg-green">HTML y CSS.</span></p>
          <p className="fontColor1"><span className="bg-green">JavaScript:</span> DOM (elementos y eventos), JSON, Métodos GET/POST y Fetch, NodeJS y ExpressJS.</p>
        </div>

        <p className="fontFamily1 fontColor1">Idioma inglés nivel B1</p>
        <a className='cursor-pointer' href='/documents/Jap.pdf' target="_blank" rel='noreferrer' ><span className="material-symbols-outlined fontColor1 navElement">link</span></a>
      </div>
    </div>

    <Separador espacios="3"/>

    {/* Certificado 2 IBM Full Stack Software Developer */}
    <div className="container d-inline-block my-4 py-4 position-relative" data-aos="fade-right">
      <div className="formacionContainer position-absolute d-inline-block">
        <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/F66ETSSMMUUL" target="_blank" rel='noreferrer' >
          <img src={require("../img/ibm1.jpeg")} alt="" className="img-fluid col-md-7 imgPhoto rounded position-relative float-end" />
        </a>
      </div>

      <div className="formacionContainer position-relative w-50 mt-5">
        <p className="fontFamily1 bg-green">IBM Full Stack Software Developer</p>
        <h5 className="fontColor1 fontFamily2">IBM</h5>
        <div className="bg-blue shadow p-3 rounded">
          <p className="fontColor1">Habilidades y herramientas prácticas más actualizadas que los desarrolladores full stack usan en sus roles diarios. Desarrollo de software con lenguajes y herramientas front-end como <span className="bg-green">HTML, CSS, JavaScript, React y Bootstrap.</span></p>
        </div>

        <p className="fontFamily1 fontColor1">Introducción a Git y Github</p>
        <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/F66ETSSMMUUL" target="_blank" rel='noreferrer' ><span className="material-symbols-outlined fontColor1 navElement">link</span></a>
      </div>
    </div>

    <Separador espacios="5" />

    {/* Certificado 3 IBM DevOps and Software Engineering */}
    <div className="container d-inline-block my-4 py-4 position-relative" data-aos="fade-left" style={{ height: '50vh' }}>
      <div className="formacionContainer position-absolute d-inline-block">
        <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/3NY76ZKQAFG5" target="_blank" rel='noreferrer' >
          <img src={require("../img/ibm2.jpeg")} alt="" className="img-fluid col-md-7 imgPhoto rounded position-relative" />
        </a>
      </div>

      <div className="formacionContainer position-relative float-end floatDiv w-50 mt-5">
        <p className="fontFamily1 bg-green">IBM DevOps and Software Engineering</p>
        <h5 className="fontColor1 fontFamily2">IBM</h5>
        <div className="bg-blue shadow p-3 rounded">
          <p className="fontColor1">Habilidades y conocimientos prácticos actualizados que los ingenieros de software y los profesionales de DevOps utilizan en sus roles diarios.</p>
        </div>

        <p className="fontFamily1 fontColor1">Introducción a Docker, Kubernetes y OpenShift</p>
        <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/3NY76ZKQAFG5" target="_blank" rel='noreferrer' ><span className="material-symbols-outlined fontColor1 navElement">link</span></a>
      </div>
    </div>

  </div>
  )
}

export default Formacion