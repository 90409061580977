import React, { useLayoutEffect } from 'react'
import { Link } from 'react-scroll';

function NavBar(props) {

  useLayoutEffect(() => {
    // Obtén todos los elementos con la clase 'js-scroll-trigger'
    var scrollTriggers = document.querySelectorAll('.js-scroll-trigger');

    // Itera sobre cada elemento y agrega un event listener
    scrollTriggers.forEach(function(trigger) {
        trigger.addEventListener('click', function() {
            // Obtén el elemento con la clase 'navbar-collapse' y ciérralo
            var navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
            }
        });
    });

  }, [])

  return (
    <header className="mb-5 fixed-top shadow-lg" data-aos="fade-down" data-aos-delay="3000">
      <nav className="navbar navbar-expand-lg navbar-light colorFondoNav px-5">
        <Link className="navbar-brand pt-3 cursor-pointer" to="htmlMain" >
          <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0.8 0.0 62.4 64.0" zoomAndPan="magnify" style={{ fill: 'rgb(0, 0, 0)' }} originalstringlength="1407">
            <defs>
              <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" gradientUnits="userSpaceOnUse" id="__id139_sxoet6vev8" xlinkActuate="onLoad" xlinkShow="other" xlinkType="simple">
                <stop offset=".18" style={{ stopColor: 'rgb(61, 136, 156)' }} />
                <stop offset="1" style={{ stopColor: 'rgb(94, 238, 204)' }} />
              </linearGradient>
              <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" id="__id140_sxoet6vev8" x1="42.023" x2="44.133" xlinkActuate="onLoad" xlinkHref="#__id139_sxoet6vev8" xlinkShow="other" xlinkType="simple" y1="35.232" y2="33.122" />
              <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" id="__id141_sxoet6vev8" x1="41.464" x2="39.35" xlinkActuate="onLoad" xlinkHref="#__id139_sxoet6vev8" xlinkShow="other" xlinkType="simple" y1="29.159" y2="31.273" />
            </defs>
            <g transform="matrix(6.2496 0 0 6.2496 -228.82 -169.26)">
              <g id="__id142_sxoet6vev8">
                <path d="m46.568 31.918l-4.834-4.834-4.834 4.834a0.406 0.406 0 0 0 0 0.573l4.834 4.834 4.834-4.834a0.406 0.406 0 0 0 0 -0.573zm-4.834 1.8l-1.514-1.514 1.514-1.514 1.514 1.514z" style={{ fill: 'rgb(92, 233, 200)' }} />
              </g>
              <path d="m41.734 30.7a2.549 2.549 0 0 1 -0.011 -3.594l-3.323 3.302 1.803 1.803z" style={{ fill: 'url("#__id141_sxoet6vev8")' }} />
              <path d="m43.252 32.2l-1.518 1.518a2.549 2.549 0 0 1 0 3.606l3.32-3.32z" style={{ fill: 'url("#__id140_sxoet6vev8")' }} />
            </g>
          </svg>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto">
            <Link className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" to="aboutMe" >
              <p className="m-0 d-inline bg-green navNumbersGreen">01.</p> Acerca de mí
            </Link>
            <Link className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" to="skills">
              <p className="m-0 d-inline bg-green navNumbersGreen">02.</p> Conocimientos
            </Link>
            <Link className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" to="training" >
              <p className="m-0 d-inline bg-green navNumbersGreen">03.</p> Formación
            </Link>
            <Link className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" to="projects" >
              <p className="m-0 d-inline bg-green navNumbersGreen">04.</p> Proyectos
            </Link>
            <Link className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" to="contact" >
              <p className="m-0 d-inline bg-green navNumbersGreen">05.</p> Contacto
            </Link>
            <a className="nav-item nav-link cursor-pointer mx-3 fontColor1 fontFamily1 navElement js-scroll-trigger" href={props.curriculum} target="_blank" rel="noreferrer">
              <p className="m-0 d-inline bg-green navNumbersGreen">06.</p> CV
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default NavBar