import React from 'react'
import { Link } from 'react-scroll';

function NombreDiv() {
  return (
    <div className="container titleContainer" id="myName" data-aos="fade" data-aos-delay="3000">
            <h1 className="bg-green fontFamily1 h5">Hola, mi nombre es</h1>
            <h2 className="display-1 fontColor1 fontFamily2">Martin Nuñez.</h2>

            <p className="colorTexto w-50 mb-5">Soy un estudiante de Ingeniería en Computación y Desarrollador Web Junior. 
              Estoy en constante aprendizaje y motivado a seguir ampliando mis habilidades en el campo del desarrollo web.
            </p>

            <Link className="buttonGreen p-3 cursor-pointer" to='aboutMe'>Conocer mas!</Link>

        </div>
  )
}

export default NombreDiv