import React from 'react'

function Footer(props) {

  const fechaActual = new Date();


  return (
    <div className="newContainer2 text-center footer mt-0">

    <p className="fontColor1 fontFamily1 mb-0 d-inline-block px-1">Martin Nuñez </p>
    <p className="colorTexto fontFamily1 mb-0 d-inline-block px-1">Portfolio</p>

    <div>
      <a className="navElement fontColor1 px-1 cursor-pointer" style={{ textDecoration: 'none' }} href={ props.gitHubLink } target="_blank" rel='noreferrer' >Github</a>
      <a className="navElement fontColor1 px-1 cursor-pointer" style={{ textDecoration: 'none' }} href={ props.linkedinLink } target="_blank" rel='noreferrer' >Linkedin</a>
      <a className="navElement fontColor1 px-1 cursor-pointer" style={{ textDecoration: 'none' }} href={props.curriculum} target="_blank" rel='noreferrer' >CV</a>
    </div>

    <div>
      <span className="material-symbols-outlined fontColor1" style={{ fontSize: '9px' }}>circle</span>
      <p className="colorTexto fontFamily1 d-inline-block">{fechaActual.getFullYear().toString()}</p>
      <span className="material-symbols-outlined fontColor1" style={{ fontSize: '9px' }}>circle</span>
    </div>

  </div>
  )
}

export default Footer