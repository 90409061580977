import './App.css';
import AboutMe from './componentes/AboutMe';
import Conocimientos from './componentes/Conocimientos';
import ContactoDiv from './componentes/ContactoDiv';
import DivFlotanteLaterales from './componentes/DivFlotanteLaterales';
import Footer from './componentes/Footer';
import Formacion from './componentes/Formacion';
import LoadScreen from './componentes/LoadScreen';
import NavBar from './componentes/NavBar';
import NombreDiv from './componentes/NombreDiv';
import Proyectos from './componentes/Proyectos';
import StarsCanvas from './componentes/StarsCanvas';


function App() {

  /* Email de contacto */
  const emailDeContacto = 'martin@martinn.uy';

  /* Links */
  const CurriculumObicacion = '/documents/CV-Martin-Nuñez-Desarrollador-Web.pdf';
  const gitHubLink = 'https://github.com/martinnuy';
  const linkedinLink = 'https://www.linkedin.com/in/martinnuy/';

  return (
    <div className="App">
      
      <LoadScreen /> 
      <NavBar curriculum={ CurriculumObicacion } />
      <DivFlotanteLaterales curriculum={ CurriculumObicacion } gitHubLink={ gitHubLink } linkedinLink={ linkedinLink } emailDeContacto={ emailDeContacto } />
      
      <div className='animatedBackground'>
        <StarsCanvas />
      </div>

      <div className="container mt-5 pt-5">
        
        <NombreDiv />
        <AboutMe />
        <Conocimientos />
        <Formacion />
        <Proyectos />
        <ContactoDiv emailDeContacto={ emailDeContacto } />
        <Footer curriculum={ CurriculumObicacion } gitHubLink={ gitHubLink } linkedinLink={ linkedinLink } />

      </div>

    </div>
  );
}

export default App;
