import React from 'react'
import { FaBootstrap, FaCss3Alt, FaGitAlt, FaHtml5, FaNodeJs, FaReact } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";


function Conocimientos() {
  return (
    <div className="newContainer conocimientos" id="skills" data-aos="fade">

      <div className="mb-4">
        <h4 className="bg-green fontFamily1 d-inline-block">02.</h4>
        <h2 className="fontColor1 fontFamily2 d-inline-block">Conocimientos</h2>
        <hr className="hr1 d-inline-block m-0 mb-2 mx-3" />
      </div>

      <div className='row justify-content-center mb-5 pt-4'>
        <h3 className='fontColor1 text-center mb-3'>Lenguajes y Base de datos.</h3>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaHtml5 className='bg-green-light mx-auto' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>HTML</p>
        </div>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaCss3Alt className='bg-green-light' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>CSS</p>
        </div>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><IoLogoJavascript  className='bg-green-light' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>JavaScript</p>
        </div>
        
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><SiMongodb className='bg-green-light' style={{width: '100%', height: 'auto'}}/></div>
          <p className='bg-green-light text-center h5 techTextSize'>MongoDB</p>
        </div>
      </div>

      <div className='row justify-content-center mb-5'>
        <h3 className='fontColor1 text-center mb-3'>Frameworks.</h3>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaReact className='bg-green-light mx-auto' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>ReactJS</p>
        </div>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaNodeJs className='bg-green-light' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>NodeJS</p>
        </div>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaBootstrap  className='bg-green-light' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>Bootstrap</p>
        </div>
      </div>

      <div className='row justify-content-center mb-5'>
        <h3 className='fontColor1 text-center mb-3'>Herramientas.</h3>
        <div className='col-3 col-md-2 hoverSizeEffect'>
          <div className='text-center'><FaGitAlt className='bg-green-light mx-auto' style={{width: '100%', height: 'auto'}} /></div>
          <p className='bg-green-light text-center h5 techTextSize'>Git</p>
        </div>
      </div>

      {/*<div className="col md-4 skillContainer m-2 p-4 rounded">
        <span className="material-symbols-outlined bg-green-light">data_object</span>
        <h4 className="fontColor1 fontFamily1">Lenguajes</h4>
        <p className="colorTexto m-0"> <span className="bg-green-light">•</span> JavaScript</p>
        <p className="colorTexto m-0"> <span className="bg-green-light">•</span> HTML</p>
        <p className="colorTexto m-0"> <span className="bg-green-light">•</span> CSS</p>
      </div>

      <div className="col md-4 skillContainer m-2 p-4 rounded">
        <span className="material-symbols-outlined bg-green-light">dashboard</span>
        <h4 className="fontColor1 fontFamily1">Frameworks</h4>
        <p className="colorTexto m-0"> <span className="bg-green-light">•</span> Bootstrap</p>
      </div>

      <div className="col md-4 skillContainer m-2 p-4 rounded">
        <span className="material-symbols-outlined bg-green-light">build</span>
        <h4 className="fontColor1 fontFamily1">Herramientas</h4>
        <p className="colorTexto m-0"> <span className="bg-green-light">•</span> Git & Github</p>
      </div> */}

    </div>
  )
}

export default Conocimientos