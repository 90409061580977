import React from 'react'

function BotonTecnologia(props) {
  return (
    <div className="d-inline-block m-auto fontFamily1 techDiv me-2 my-1">
                <p className="mb-0 mx-3">{props.nombre}</p>
    </div>
  )
}

export default BotonTecnologia